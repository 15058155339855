<template>
	<div id="task_manage_detail">
		<el-drawer custom-class="detail-main" v-model="pageConf.show" direction="rtl" destroy-on-close
			:show-close="false">
			<template #title>
				<span class="title_close" @click="pageConf.show = false">
					<i class="iconfont icon-edit">&#xe6af;</i> 任务列表
				</span>
				<span class="title_id">{{taskInfo.main.task_id}}</span>
				<span class="title_name">{{taskInfo.main.title}}</span>
			</template>
			<el-row :gutter="24">
				<el-col class="left-body" :span="16">
					<el-skeleton :rows="22" animated :loading="pageConf.left_isLoading">
						<template #default>
							<div class="main-task">
								<div class="title">任务详情</div>
								<div v-html="taskInfo.main.content" @click="showImg"></div>
							</div>
							<div class="list-task">
								<div class="sub-title">子任务</div>
								<ul class="body">
									<li v-for="(v,i) in taskInfo.list">
										<el-row :gutter="24">
											<el-col class="list-content list-sort" :span="1">
												{{i+1}}
											</el-col>
											<el-col :class="['list-content','list-text-'+v.status]" :span="12">
												{{v.title}}
											</el-col>
											<el-col :class="['list-user','list-text-'+v.status]" :span="2">
												{{v.deal_uname}}
											</el-col>

											<!-- <el-col @click="changeTime(v,i)" :class="['list-user','list-text-'+v.status]" :span="3"
												v-if="v.status != 0">
												{{v.plan_suc_time}}
											</el-col> -->


											<el-col :class="['list-user','list-text-'+v.status]" :span="6"
												v-if="v.status != 0">
												<el-date-picker
													v-if="$pubFun_.verify_permiss($api_.state.Task.TaskStatistics.list_edit_time.token,$menu_.state.roleNode)"												   @change="changeTime(v,i)"
													:disabled-date="disabledDate"
													v-model="v.plan_suc_time" type="date"  />
												<span v-else>{{v.plan_suc_time}}</span>

											</el-col>
											<el-col :class="['list-user','list-text-'+v.status]" :span="6" v-else>
												{{v.plan_suc_time}}
											</el-col>
											<el-col :class="['list-status','task_status_'+v.status]" :span="2">
												<span v-if="v.status === 0">待分派</span>
												<span v-else-if="v.status === 1">未开始</span>
												<span v-else-if="v.status === 2">进行中</span>
												<span v-else-if="v.status === 3">已完成</span>
												<span v-else-if="v.status === 4">已撤销</span>
												<span v-else-if="v.status === 5">已暂停</span>
												<span v-else-if="v.status === 6">转交中</span>
											</el-col>
										</el-row>
									</li>
								</ul>
							</div>
							<div class="work-module">
								<div class="sub-title">涉及板块</div>
								<div class="body">
									<span class="list" v-for="(v,i) in taskInfo.module">{{v}}</span>
								</div>
							</div>
							<div class="task_date">
								<div class="list" v-if="taskInfo.main.task_days">任务时长 <span
										class="value">{{taskInfo.main.task_days}}天</span></div>
								<div class="list" v-if="taskInfo.main.online_date">上线日期 <span
										class="value">{{taskInfo.main.online_date}}</span></div>
							</div>
							<div class="task_date">
								<div class="list" v-if="taskInfo.main.active_date">激活日期 <span
										class="value">{{taskInfo.main.active_date}}</span></div>
								<div class="list" v-if="taskInfo.main.suc_date">完成日期 <span
										class="value">{{taskInfo.main.suc_date}}</span></div>
							</div>
							<div class="task_btns" v-if="is_manage_page === true">
								<div class="list" v-if="taskInfo.main.is_headmaner === true">
									<el-button type="primary" @click="activeTask" size="medium"
										v-if="taskInfo.main.status === 1">
										激活任务</el-button>
									<el-button type="primary" @click="openAssignTaskPage" size="medium"
										v-if="showAssignBtn()">
										任务分派</el-button>
									<el-button type="primary" @click="openDelayTaskPage" plain size="medium"
										v-if="taskInfo.main.status === 2">
										申请延期</el-button>
								</div>
								<div class="list">
									<el-button type="primary" @click="editTask" size="medium"
										v-if="taskInfo.main.status != 5 && $pubFun_.verify_permiss($api_.state.Task.TaskManage.edit.token,$menu_.state.roleNode)">
										编辑任务</el-button>
									<el-button type="primary" @click="revokeTask" plain size="medium"
										v-if="(taskInfo.main.status === 1 || taskInfo.main.status === 2 ) && $pubFun_.verify_permiss($api_.state.Task.TaskManage.revoke.token,$menu_.state.roleNode)">
										撤回任务</el-button>
									<el-button type="default" @click="releaseTask" plain size="medium"
										v-if="(taskInfo.main.status === 3) && $pubFun_.verify_permiss($api_.state.Task.TaskManage.release.token,$menu_.state.roleNode)">
										发布任务</el-button>
								</div>
							</div>
						</template>
					</el-skeleton>
				</el-col>
				<el-col class="right-body" :span="8">
					<el-skeleton :rows="22" animated :loading="pageConf.right_isLoading">
						<template #default>
							<ul class="task-log">
								<li class="task-log-li" v-for="(v,i) in taskInfo.log">
									<el-row>
										<el-col :span="10">
											<span class="circle" :style="'background-color:'+v.hex_color"></span>
											<span class="title">{{v.title}}:</span>
											<span class="user">{{v.uname}}</span>
											<span class="duty">({{v.duty_name}})</span>
										</el-col>
										<el-col :span="8">
											<div class="remark-div" v-if="v.type === 1">
												<span class="remark-text">{{v.remark}}</span>
												<el-popover placement="top" trigger="hover" :content="v.remark2"
													width="10vw" v-if="v.remark2">
													<template #reference>
														<el-link type="primary">(详情)</el-link>
													</template>
												</el-popover>
											</div>
											<el-popover popper-class="popover-task-log-change" placement="top"
												trigger="hover" v-else-if="v.type === 2">
												<template #reference>
													<el-link type="primary">查看详情<i class="el-icon-arrow-right"></i>
													</el-link>
												</template>
												<el-row>
													<el-col :span="6">
														<span class="title">原子任务</span>
													</el-col>
													<el-col :span="18">
														<span class="content">{{v.remark2}}</span>
													</el-col>
												</el-row>
												<el-row>
													<el-col :span="6">
														<span class="title">变更为</span>
													</el-col>
													<el-col :span="18">
														<span class="content">{{v.remark}}</span>
													</el-col>
												</el-row>
											</el-popover>
											<el-popover placement="top" trigger="hover" :content="v.remark" width="10vw"
												v-else-if="v.type === 3 && v.remark">
												<template #reference>
													<el-link type="primary">查看备注<i class="el-icon-arrow-right"></i>
													</el-link>
												</template>
											</el-popover>
											<el-popover popper-class="popover-task-log-change" placement="top"
												trigger="hover" v-else-if="v.type === 4">
												<template #reference>
													<el-link type="primary">查看详情<i class="el-icon-arrow-right"></i>
													</el-link>
												</template>
												<el-row>
													<el-col :span="4">
														<span class="title">任务</span>
													</el-col>
													<el-col :span="20">
														<span class="content">{{v.remark}}</span>
													</el-col>
												</el-row>
												<el-row>
													<el-col :span="4">
														<span class="title">备注</span>
													</el-col>
													<el-col :span="20">
														<span class="content">{{v.remark2 ? v.remark2 : "无备注说明"}}</span>
													</el-col>
												</el-row>
											</el-popover>
										</el-col>
										<el-col :span="6">
											<span class="time">{{v.ctime}}</span>
										</el-col>
									</el-row>
								</li>
							</ul>
						</template>
					</el-skeleton>
				</el-col>
			</el-row>
		</el-drawer>
		<!-- 图片详情 -->
		<el-dialog v-model="pageConf.taskImgShow" destroy-on-close>
			<div style="max-height: 65vh;text-align: center;overflow-y: auto;">
				<el-image :src="pageConf.taskImgSrc" :fit="contain"></el-image>
			</div>
		</el-dialog>
		<!-- 图片详情 -->
		<!-- 申请延期 -->
		<el-dialog title="申请延期" v-model="pageConf.delay" destroy-on-close :width="400">
			<el-form :model="delay.datas" :rules="delay.rules" ref="formRef">
				<el-form-item prop="days">
					<el-input v-model="delay.datas.days" type="number" class="form-input" placeholder="请输入延期天数">
					</el-input>
				</el-form-item>
			</el-form>
			<div class="delay-sub-title">
				<span class="title">
					原任务 <span>{{taskInfo.main.task_days}}</span> 天
				</span>
				<span class="title">
					延后任务期限
					<span>{{parseInt(taskInfo.main.task_days)+(delay.datas.days ? parseInt(delay.datas.days) : 0)}}</span>
					天
				</span>
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button type="primary" :loading="pageConf.submit_btn_isload" @click="delaySubmit()">确认发起
					</el-button>
					<el-button @click="pageConf.delay = false">取 消</el-button>
				</span>
			</template>
		</el-dialog>
		<!-- 申请延期 -->
		<!-- 任务分派 -->
		<el-dialog title="任务分派" v-model="pageConf.assign" destroy-on-close :width="800">
			<el-form class="assign-form" :model="assign.datas" :rules="assign.rules" ref="formRef">
				<div class="assign-sub-title">选择子任务</div>
				<el-form-item prop="task_list_id">
					<ul class="assign-ul">
						<li v-for="(v,i) in taskInfo.list">
							<el-row
								:class="['assign-row',assign.datas.task_list_id.indexOf(v.task_list_id) > -1 ? 'assign-row-active' : '']"
								:gutter="24" @click="choseAssignTask(i)" v-if="v.status === 0">
								<el-col class="list-content list-sort" :span="2">
									Tip{{i+1}}
								</el-col>
								<el-col :class="['list-content']" :span="20">
									{{v.title}}
								</el-col>
								<el-col :class="['list-content']" :span="2">
									<i class="iconfont"
										v-if="assign.datas.task_list_id.indexOf(v.task_list_id) > -1">&#xe6b2;</i>
								</el-col>
							</el-row>
						</li>
					</ul>
				</el-form-item>
				<el-form-item v-if="assign.datas.task_list.length > 0">
					<div class="assign-sub-title">选择预估完成日期</div>
					<el-row class="assign-sub-time" :gutter="24">
						<el-col :span="6" v-for="(v,i) in assign.datas.task_list">
							<span class="time-sort">Tip{{v.index+1}}</span>
							<el-date-picker v-model="v.plan_suc_time" placeholder="预估完成日期"
								:disabled-date="assign.datas.planTimeChoRange"></el-date-picker>
						</el-col>
					</el-row>
				</el-form-item>
				<div class="assign-sub-title">选择组员</div>
				<el-form-item prop="group_uid">
					<el-radio-group v-model="assign.datas.group_uid">
						<el-radio-button v-for="v in taskInfo.group" :label="v.u_id">
							{{v.u_name}}
						</el-radio-button>
					</el-radio-group>
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button type="primary" :loading="pageConf.submit_btn_isload" @click="assignSubmit()">确认分派
					</el-button>
					<el-button @click="pageConf.assign = false">取 消</el-button>
				</span>
			</template>
		</el-dialog>
		<!-- 任务分派 -->
	</div>
</template>

<script>
	import {
		getCurrentInstance,
		defineComponent,
		ref,
		reactive,
		onMounted,
		watch
	} from 'vue'
	export default defineComponent({
		name: 'task_manage_detail',
		props: ['is_manage_page'],
		setup(props, context) {
			// 访问 globalProperties
			const ctx = getCurrentInstance().appContext.config.globalProperties

			// 弹出层配置文件
			const pageConf = reactive({
				show: false,
				taskImgSrc: '',
				taskImgShow: false,
				delay: false,
				assign: false,
				left_isLoading: false,
				right_isLoading: false,
				submit_btn_isload: false
			})

			// 表单-挂载
			const formRef = ref()

			// 任务详情
			const taskInfo = reactive({
				main: {},
				list: [],
				module: [],
				group: {},
				log: []
			})

			// 是否显示任务分派按钮
			const showAssignBtn = () => {
				let isShow = false
				if (taskInfo.main.status === 2 && taskInfo.list.length > 0) {
					taskInfo.list.forEach((v, i) => {
						if (v.status === 0) {
							isShow = true
						}
					})
				}

				return isShow
			}

			// 是否显示任务移交按钮
			const showBtnFransferBtn = () => {
				let isShow = false
				if (taskInfo.main.status === 2) {
					taskInfo.list.forEach((v, i) => {
						if (v.is_deal_user === true) {
							isShow = true
						}
					})
				}

				return isShow
			}

			// 显示页面
			const openPage = (task_id) => {
				// 显示页面
				pageConf.show = true

				// 获取任务详情
				getTaskInfo(task_id)

				// 获取行为日志
				getTaskLog(task_id)
			}

			// 获取任务详情
			const getTaskInfo = (task_id) => {
				// 打开骨架屏
				pageConf.left_isLoading = true

				// 获取任务详情
				ctx.$request_nl_.post(ctx.$api_.state.Task.TaskManage.detail.url, {
						task_id: task_id,
					})
					.then((respon) => {
						if (respon.Code === 200) {
							// 加载主任务信息
							taskInfo.main = respon.Data.main

							// 加载子任务信息
							taskInfo.list = respon.Data.list

							// 加载板块信息
							taskInfo.module = respon.Data.module

							// 加载团队成员信息
							taskInfo.group = respon.Data.group

							pageConf.left_isLoading = false
						} else {
							ctx.$notify({
								title: '温馨提示',
								message: respon.Message,
								type: 'warning'
							})
						}
					})
			}

			// 获取行为日志
			const getTaskLog = (task_id, task_list_id = 0) => {
				// 打开骨架屏
				pageConf.right_isLoading = true

				// 获取行为日志
				ctx.$request_nl_.post(ctx.$api_.state.Task.TaskManage.log.url, {
						task_id: task_id,
						task_list_id: task_list_id
					})
					.then((respon) => {
						if (respon.Code === 200) {
							// 加载行为日志信息
							taskInfo.log = respon.Data.log

							pageConf.right_isLoading = false
						} else {
							ctx.$notify({
								title: '温馨提示',
								message: respon.Message,
								type: 'warning'
							})
						}
					})
			}

			//展示任务详情大图
			const showImg = (event) => {
				if (event.target.tagName == 'IMG') {
					pageConf.taskImgSrc = event.target.src
					pageConf.taskImgShow = true
				}
			}

			// 激活任务
			const activeTask = () => {
				ctx.$confirm('请确认是否激活该任务?', '温馨提示', {
						confirmButtonText: '确认激活',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						ctx.$request_.post(ctx.$api_.state.Task.TaskManage.active.url, {
								task_id: taskInfo.main.task_id,
							})
							.then((respon) => {
								if (respon.Code === 200) {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'success'
									})

									// 重新获取任务详情
									getTaskInfo(taskInfo.main.task_id)

									// 重新获取行为日志
									getTaskLog(taskInfo.main.task_id)

									// 抛出
									context.emit('load_table')
								} else {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'warning'
									})
								}
							})
					})
					.catch(() => {})
			}

			// 申请延期-数据表单
			const delay = reactive({
				datas: {
					days: ''
				},
				rules: {
					days: {
						required: true,
						message: '请输入延期天数',
						trigger: 'blur'
					}
				}
			})

			// 申请延期-打开页面
			const openDelayTaskPage = () => {
				// 重置数据表单
				delay.datas.days = ''

				// 打开页面
				pageConf.delay = true
			}

			// 申请延期-提交数据
			const delaySubmit = () => {
				formRef.value.validate((valid) => {
					if (valid === true) {
						// 打开按钮加载状态
						pageConf.submit_btn_isload = true

						ctx.$request_nl_.post(ctx.$api_.state.Task.TaskManage.delay.url, {
								task_id: taskInfo.main.task_id,
								days: parseInt(delay.datas.days)
							})
							.then((respon) => {
								if (respon.Code === 200) {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'success'
									})

									// 重新获取任务详情
									getTaskInfo(taskInfo.main.task_id)

									// 重新获取行为日志
									getTaskLog(taskInfo.main.task_id)

									// 关闭弹出层
									pageConf.delay = false

									// 抛出
									context.emit('load_table')
								} else {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'warning'
									})
								}

								// 关闭加载状态
								pageConf.submit_btn_isload = false
							}).catch(error => {
								// 关闭加载状态
								pageConf.submit_btn_isload = false
							})
					}
				})
			}

			// 任务分派-数据表单
			const assign = reactive({
				datas: {
					task_list_id: [],
					task_list: [],
					group_uid: '',
					planTimeChoRange(time) {
						return time.getTime() > new Date(taskInfo.main.online_date)
					}
				},
				rules: {
					task_list_id: {
						required: true,
						message: '请选择子任务',
						trigger: 'blur'
					},
					group_uid: {
						required: true,
						message: '请选择组员',
						trigger: 'blur'
					}
				}
			})

			// 任务分派-打开页面
			const openAssignTaskPage = () => {
				// 重置数据表单
				assign.datas.task_list_id = []
				assign.datas.task_list = []
				assign.datas.group_uid = ''

				// 打开页面
				pageConf.assign = true
			}

			// 任务分派-选择子任务
			const choseAssignTask = (index) => {
				let assignIndex = assign.datas.task_list_id.indexOf(taskInfo.list[index].task_list_id);
				if (assignIndex > -1) {
					assign.datas.task_list_id.splice(assignIndex, 1)
					assign.datas.task_list.splice(assignIndex, 1)
				} else {
					assign.datas.task_list_id.push(taskInfo.list[index].task_list_id)
					assign.datas.task_list.push({
						task_list_id: taskInfo.list[index].task_list_id,
						index: index,
						plan_suc_time: ''
					})
					assign.datas.task_list_id = assign.datas.task_list_id.sort()
					assign.datas.task_list = ctx.$pubFun_.reverseByKey(assign.datas.task_list, 'index')
				}
			}

			// 任务分派-提交数据
			const assignSubmit = () => {
				formRef.value.validate((valid) => {
					if (valid === true) {
						//判断子任务预估完成日期是否填写
						var isInputTime = true
						assign.datas.task_list.forEach((v, i) => {
							if (!v.plan_suc_time) {
								isInputTime = false
							}
						})

						if (isInputTime === false) {
							ctx.$message.error('请完善每个子任务预估完成日期')
							return false
						}

						//打开按钮加载状态
						pageConf.submit_btn_isload = true

						ctx.$request_nl_.post(ctx.$api_.state.Task.TaskManage.assign.url, {
								task_id: taskInfo.main.task_id,
								task_list: assign.datas.task_list,
								group_uid: assign.datas.group_uid
							})
							.then((respon) => {
								if (respon.Code === 200) {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'success'
									})

									// 重新获取任务详情
									getTaskInfo(taskInfo.main.task_id)

									// 重新获取行为日志
									getTaskLog(taskInfo.main.task_id)

									// 关闭弹出层
									pageConf.assign = false

									// 抛出
									context.emit('load_table')
								} else {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'warning'
									})
								}

								// 关闭加载状态
								pageConf.submit_btn_isload = false
							}).catch(error => {
								// 关闭加载状态
								pageConf.submit_btn_isload = false
							})
					}
				})
			}

			// 编辑任务操作指令
			const editTask = () => {
				// 关闭页面
				pageConf.show = false

				// 抛出
				context.emit('edit_task', -1, taskInfo.main.task_id)
			}

			// 撤回任务
			const revokeTask = () => {
				ctx.$prompt('请输入撤回原因', '温馨提示', {
						confirmButtonText: '确认撤回',
						cancelButtonText: '取消'
					})
					.then(({
						value
					}) => {
						ctx.$request_.post(ctx.$api_.state.Task.TaskManage.revoke.url, {
								task_id: taskInfo.main.task_id,
								remark: typeof value === 'undefined' ? '' : value
							})
							.then((respon) => {
								if (respon.Code === 200) {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'success'
									})

									// 重新获取任务详情
									getTaskInfo(taskInfo.main.task_id)

									// 重新获取行为日志
									getTaskLog(taskInfo.main.task_id)

									// 关闭弹出层
									pageConf.assign = false

									// 抛出
									context.emit('load_table')
								} else {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'warning'
									})
								}
							})
					})
					.catch(() => {})
			}

			// 发布任务
			const releaseTask = () => {
				ctx.$confirm('请确认是否发布该任务?', '温馨提示', {
						confirmButtonText: '确认',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						ctx.$request_.post(ctx.$api_.state.Task.TaskManage.release.url, {
								task_id: taskInfo.main.task_id,
							})
							.then((respon) => {
								if (respon.Code === 200) {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'success'
									})

									// 重新获取任务详情
									getTaskInfo(taskInfo.main.task_id)

									// 重新获取行为日志
									getTaskLog(taskInfo.main.task_id)

									// 抛出
									context.emit('load_table')
								} else {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'warning'
									})
								}
							})
					})
					.catch(() => {})
			}
			//修改子任务时间
			const changeTime = (v, i) => {
			
				if (!ctx.$pubFun_.verify_permiss(ctx.$api_.state.Task.TaskStatistics.list_edit_time.token, ctx
						.$menu_.state.roleNode)) {
					return
				}

				ctx.$request_nl_.post(ctx.$api_.state.Task.TaskStatistics.list_edit_time.url, {
						ytl_id: v.task_list_id,
						ytl_plan_time:new Date(v.plan_suc_time).getTime()/ 1000
					})
					.then((respon) => {
						if(respon.Code ==200){
							ctx.$notify({
								title: '温馨提示',
								message: respon.Message,
								type: 'success'
							})
						}else{
							ctx.$notify({
								title: '温馨提示',
								message: respon.Message,
								type: 'error'
							})
						}
						
					})
			}
			
			const disabledDate = (time) => {
			  let nowtime = new Date(taskInfo.main.online_date)
			  return time.getTime() > nowtime.getTime()
			}

			return {
				disabledDate,
				changeTime,
				pageConf,
				formRef,
				taskInfo,
				openPage,
				showAssignBtn,
				showBtnFransferBtn,
				activeTask,
				openDelayTaskPage,
				delay,
				delaySubmit,
				openAssignTaskPage,
				assign,
				choseAssignTask,
				assignSubmit,
				editTask,
				revokeTask,
				releaseTask,
				showImg
			}
		}
	})
</script>

<style lang="less">
	#task_manage_detail {
		.el-overlay {
			height: 100%;

			.detail-main {
				width: 89.5% !important;
				height: 93.2%;
				top: auto;

				.el-drawer__header {
					margin-bottom: 0;
					padding-bottom: 1vw;
					border-bottom: 1px solid #e6e6e6;

					.title_close {
						flex: none;
						color: #999999;
						font-size: 0.8vw;
						cursor: pointer;
						width: 5vw;
						border-right: 2px solid #e6e6e6;

						i {
							font-size: 0.8vw;
						}
					}

					.title_id {
						display: inline-block;
						background-color: #409eff;
						color: #ffffff;
						text-align: center;
						min-width: 0.6vw;
						height: 0.8vw;
						line-height: 0.8vw;
						font-size: 0.6vw;
						padding: 0.2vw;
						border-radius: 0.1vw;
						margin-left: 1vw;
					}

					.title_name {
						height: 0.8vw;
						line-height: 0.8vw;
						margin-left: 0.3vw;
						font-size: 0.9vw;
						font-weight: 600;
						color: #1a1a1a;
					}
				}

				.el-drawer__body {
					height: 100%;
					padding: 0 1vw;
					background-color: #fafbfc;

					.el-row {
						height: 100%;

						.left-body {
							height: 100%;
							padding-top: 1vw;
							border-right: 1px solid #e6e6e6;

							.main-task {
								height: 18vw;
								overflow-y: auto;
								overflow-x: hidden;
								background-color: #FFFFFF;
								border: 1px solid #e6e6e6;
								padding: 0.4vw 0.6vw;

								.title {
									margin-bottom: 0.4vw;
									color: #1a1a1a;
								}
							}

							.main-task::-webkit-scrollbar {
								width: 4px;
							}

							.main-task::-webkit-scrollbar-thumb {
								border-radius: 10px;
								-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
								background: rgba(0, 0, 0, 0.2);
							}

							.main-task::-webkit-scrollbar-track {
								-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
								border-radius: 0;
								background: rgba(0, 0, 0, 0.1);
							}

							.sub-title {
								margin-top: 1vw;
								font-size: 0.6vw;
								color: #999999;
							}

							.list-task {
								.body {
									max-height: 12vw;
									overflow-y: auto;
									overflow-x: hidden;
									list-style: none;
									padding: 0;

									li {
										min-height: 1vw;
										font-size: 0.7vw;
										margin-top: 0.4vw;
										border-left: 4px solid #1fb6ef;
										padding: 0.4vw;
										background-color: #f5f6f7;

										.list-content {
											color: #666666;
											
										}

										.list-sort {
											display: flex;
											justify-content: center;
											align-items: center;
											color: #333333;
											border-right: 1px solid #e6e6e6;
										}

										.list-user,
										.list-status {
											display: flex;
											justify-content: center;
											align-items: center;
											color: #333333;
											border-left: 1px solid #e6e6e6;
										}
										.list-text-1 {
											display: flex;
											align-items: center;
										}
										.list-text-3,
										.list-text-4 {
											color: #cccccc;
										}
									}
								}

								.body::-webkit-scrollbar {
									width: 4px;
								}

								.body::-webkit-scrollbar-thumb {
									border-radius: 10px;
									-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
									background: rgba(0, 0, 0, 0.2);
								}

								.body::-webkit-scrollbar-track {
									-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
									border-radius: 0;
									background: rgba(0, 0, 0, 0.1);
								}
							}


							.work-module {
								.body {
									margin-top: 0.4vw;

									.list {
										display: inline-block;
										margin-right: 0.4vw;
										padding: 0.4vw;
										color: #409eff;
										font-size: 0.7vw;
										background-color: #f2f6ff;
									}
								}
							}

							.task_date {
								margin-top: 1vw;

								.list {
									display: inline-block;
									margin-right: 1vw;
									color: #999999;
									font-size: 0.8vw;

									.value {
										color: #1a1a1a;
										font-weight: 600;
									}
								}
							}

							.task_btns {
								position: fixed;
								bottom: 0.6vw;
								left: 35%;
								text-align: center;

								.list {
									margin: 1vw;
								}
							}
						}

						.right-body {
							padding-top: 1vw;
							height: 97%;
							overflow-y: auto;

							.task-log {
								padding: 1vw 0.6vw;
								list-style: none;
							}

							.task-log-li {
								width: 100%;
								height: 2.8vw;
								font-size: 0.73vw;

								.circle {
									width: 10px;
									height: 10px;
									border-radius: 50%;
									display: inline-block;
								}

								.title {
									margin-left: 0.6vw;
									color: #1a1a1a;
								}

								.user {
									margin-left: 0.3vw;
									color: #999999;
								}

								.duty {
									font-size: 0.62vw;
									color: #999999;
								}

								.remark-div {
									line-height: 1.1vw;

									.remark-text {
										color: #999999;
									}

									.remark-href {
										color: #0056fc;
									}

									.el-link {
										font-size: 0.6vw;
									}
								}
							}
						}

						.right-body::-webkit-scrollbar {
							width: 4px;
						}

						.right-body::-webkit-scrollbar-thumb {
							border-radius: 10px;
							-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
							background: rgba(0, 0, 0, 0.2);
						}

						.right-body::-webkit-scrollbar-track {
							-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
							border-radius: 0;
							background: rgba(0, 0, 0, 0.1);
						}
					}
				}
			}
		}

		.delay-sub-title {
			margin-top: 0.6vw;

			.title {
				font-size: 0.7vw;
				color: #cccccc;

				span {
					color: #666666;
				}
			}
		}

		.assign-form {
			.assign-sub-title {
				font-size: 0.7vw;
				color: #cccccc;
			}

			.assign-ul {
				list-style: none;
				padding: 0;

				.assign-row {
					background-color: #f7f8fa;
					margin: 0.3vw 0 0 0 !important;
					padding: 0.5vw 0;
					line-height: 1vw;
					display: flex;
					justify-content: center;
					align-items: center;
					color: #333333;
					cursor: pointer;

					.list-sort {
						padding: 0 10px !important;
					}
				}

				.assign-row:hover {
					color: #0056fc;
				}

				.assign-row-active {
					color: #0056fc;
				}
			}

			.assign-sub-time {
				margin-bottom: 22px;

				.time-sort {
					display: inline-block;
					width: 25%;
					height: 2vw;
					line-height: 2vw;
					text-align: center;
					border-top: 1px solid #dcdfe6;
					border-left: 1px solid #dcdfe6;
					border-bottom: 1px solid #dcdfe6;
					background-color: #f5f7fa;
				}

				.el-date-editor.el-input {
					display: inline-block;
					width: 73%;
					margin: 0.5vw 0 0 0;

					.el-input__inner {
						padding-left: 10px;
						border-top-left-radius: 0;
						border-bottom-left-radius: 0;
					}

					.el-input__prefix {
						display: none;
					}
				}
			}


			.el-radio-button {
				margin: 0.5vw 0.5vw 0 0;

				.el-radio-button__inner {
					border: 1px solid #dcdfe6 !important;
					border-radius: 0.2vw !important;
					-webkit-box-shadow: none !important;
					box-shadow: none !important;
				}
			}
		}
	}

	.popover-task-log-change {
		width: 15vw !important;

		.el-row {
			.title {
				font-size: 0.73vw;
				color: #999999;
			}

			.content {
				font-size: 0.73vw;
				color: #333333;
			}
		}

		.el-row:not(:first-child) {
			margin-top: 0.5vw;
		}
	}
</style>
