<template>
	<div id="task_manage_process">
		<el-dialog v-model="pageConf.show" destroy-on-close :width="1200">
			<template #title>
				<div v-if="pageConf.model === 1">任务进度详情</div>
				<div v-else>
					<span class="title_close" @click="pageConf.model = 1">
						<i class="iconfont icon-edit">&#xe6af;</i> 任务进度详情
					</span>
				</div>
			</template>
			<div v-if="pageConf.model === 1">
				<ul class="process-ul">
					<li v-for="(v,i) in taskInfo.list">
						<div v-if="is_manage_page === true">
							<el-row class="process-row" :gutter="24">
								<el-col class="list-content list-sort" :span="2">
									Tip{{i+1}}
								</el-col>
								<el-col
									:class="['list-content-left',v.status === 3 || v.status === 4 || v.status === 5 ? 'grey-text' : '']"
									:span="14">
									{{v.title}}
								</el-col>
								<el-col class="list-content-right grey-text" :span="6">
									<span class="user">{{v.deal_uname}}</span>
									<span v-if="v.status === 1">
										<span>{{v.assign_time}}分派 已耗时{{v.use_time}}</span>
									</span>
									<el-popover placement="top" trigger="hover" :content="v.remark" width="10vw"
										v-else-if="v.status === 2">
										<template #reference>
											<span>{{v.start_time}}开始 已耗时{{v.use_time}}</span>
										</template>
										<div>分派时间 {{v.assign_time}}</div>
										<div>开始时间 {{v.start_time}}</div>
									</el-popover>
									<el-popover placement="top" trigger="hover" :content="v.remark" width="10vw"
										v-else-if="v.status === 3">
										<template #reference>
											<span>{{v.suc_time}}完成 耗时{{v.use_time}}</span>
										</template>
										<div>分派时间 {{v.assign_time}}</div>
										<div>开始时间 {{v.start_time}}</div>
										<div>完成时间 {{v.suc_time}}</div>
									</el-popover>
									<el-popover placement="top" trigger="hover" :content="v.remark" width="10vw"
										v-else-if="v.status === 5">
										<template #reference>
											<span>{{v.stop_time}}暂停 耗时{{v.use_time}}</span>
										</template>
										<div>分派时间 {{v.assign_time}}</div>
										<div>开始时间 {{v.start_time}}</div>
										<div>暂停时间 {{v.stop_time}}</div>
									</el-popover>
									<span v-if="v.status === 6">
										<span>{{v.assign_time}}分派 已耗时{{v.use_time}}</span>
									</span>
								</el-col>
								<el-col class="list-content" :span="2">
									<div v-if="v.status === 0">
										<span :class="'task_status_'+v.status">待分派</span>
									</div>
									<div v-else-if="v.status === 1">
										<span :class="'task_status_'+v.status">未开始</span>
									</div>
									<div v-else-if="v.status === 2">
										<span :class="'task_status_'+v.status">进行中</span>
									</div>
									<div v-else-if="v.status === 3">
										<span :class="'task_status_'+v.status">已完成</span>
									</div>
									<div v-else-if="v.status === 4">
										<span :class="'task_status_'+v.status">已撤销</span>
									</div>
									<div v-else-if="v.status === 5">
										<span :class="'task_status_'+v.status">已暂停</span>
									</div>
									<div v-else-if="v.status === 6">
										<span :class="'task_status_'+v.status">转交中</span>
									</div>
								</el-col>
							</el-row>
						</div>
						<div v-else>
							<el-row class="process-row" :gutter="24">
								<el-col class="list-content list-sort" :span="2">
									Tip{{i+1}}
								</el-col>
								<el-col
									:class="['list-content-left',v.status === 3 || v.status === 4 || v.status === 5 ? 'grey-text' : '']"
									:span="12">
									{{v.title}}
								</el-col>
								<el-col class="list-content-right grey-text" :span="6">
									<span v-if="v.status === 1">
										<span>{{v.assign_time}}分派 已耗时{{v.use_time}}</span>
									</span>
									<span v-if="v.status === 2">
										<span>{{v.start_time}}开始 已耗时{{v.use_time}}</span>
									</span>
									<span v-if="v.status === 3">
										<span>{{v.suc_time}}完成 耗时{{v.use_time}}</span>
									</span>
									<span v-if="v.status === 5">
										<span>{{v.stop_time}}暂停 耗时{{v.use_time}}</span>
									</span>
									<span v-if="v.status === 6">
										<span>{{v.assign_time}}分派 已耗时{{v.use_time}}</span>
									</span>
								</el-col>
								<el-col class="list-content" :span="4">
									<div v-if="v.status === 0">
										<span :class="'task_status_'+v.status">待分派</span>
									</div>
									<div v-else-if="v.status === 1">
										<el-button type="primary" plain size="mini"
											:loading="pageConf.submit_btn_isload" @click="startTaskList(false,i)">
											开始</el-button>
									</div>
									<div v-else-if="v.status === 2">
										<el-button type="default" plain size="mini"
											:loading="pageConf.submit_btn_isload" @click="sucTaskList(false,i)">
											完成</el-button>
										<el-button type="danger" plain size="mini" :loading="pageConf.submit_btn_isload"
											@click="stopTaskList(false,i)">
											暂停</el-button>
									</div>
									<div v-else-if="v.status === 3">
										<span :class="'task_status_'+v.status">已完成</span>
									</div>
									<div v-else-if="v.status === 4">
										<span :class="'task_status_'+v.status">已撤销</span>
									</div>
									<div v-else-if="v.status === 5">
										<el-button type="primary" plain size="mini"
											:loading="pageConf.submit_btn_isload" @click="startTaskList(false,i)">
											开始</el-button>
									</div>
									<div v-else-if="v.status === 6">
										<el-button type="info" plain size="mini" :loading="pageConf.submit_btn_isload"
											@click="transferConfirm(i)">转交确认</el-button>
									</div>
								</el-col>
							</el-row>
						</div>
					</li>
				</ul>
			</div>
			<div v-else>
				<el-form class="trans-form" :model="trans.datas" :rules="trans.rules" ref="formRef">
					<div class="trans-sub-title">选择子任务</div>
					<el-form-item prop="task_list_id">
						<ul class="trans-ul">
							<li v-for="(v,i) in taskInfo.list">
								<el-row
									:class="['trans-row',trans.datas.task_list_id.indexOf(v.task_list_id) > -1 ? 'trans-row-active' : '']"
									:gutter="24" @click="choseTransTask(i)"
									v-if="v.status === 1 || v.status === 2 || v.status === 5 || v.status === 6">
									<el-col class="list-content list-sort" :span="2">
										Tip{{i+1}}
									</el-col>
									<el-col :class="['list-content']" :span="20">
										{{v.title}}
									</el-col>
									<el-col :class="['list-content']" :span="2">
										<i class="iconfont"
											v-if="trans.datas.task_list_id.indexOf(v.task_list_id) > -1">&#xe6b2;</i>
									</el-col>
								</el-row>
							</li>
						</ul>
					</el-form-item>
					<div class="trans-sub-title" v-if="trans.datas.task_list.length > 0">转交理由</div>
					<el-form-item>
						<div v-for="(v,i) in trans.datas.task_list">
							<div class="trans-remark-title">{{v.task_list_id}} {{v.title}}</div>
							<el-input v-model="v.remark" :rows="2" type="textarea" placeholder="请输入转交理由..." />
						</div>
					</el-form-item>
					<div class="trans-sub-title">选择转交人</div>
					<el-form-item prop="group_uid">
						<el-radio-group v-model="trans.datas.group_uid">
							<el-radio-button v-for="v in taskInfo.group" :label="v.u_id">
								{{v.u_name}}
							</el-radio-button>
						</el-radio-group>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer" v-if="is_manage_page != true">
					<div v-if="pageConf.model === 1">
						<el-button type="primary" plain size="small" :loading="pageConf.submit_btn_isload"
							@click="startTaskList(true)" v-if="showStartBtn()">批量开始</el-button>
						<el-button type="default" plain size="small" :loading="pageConf.submit_btn_isload"
							@click="sucTaskList(true)" v-if="showSucBtn()">批量完成
						</el-button>
						<el-button type="warning" plain size="small" :loading="pageConf.submit_btn_isload"
							@click="cutToTransPage()" v-if="showTransBtn()">任务转交
						</el-button>
					</div>
					<div v-else>
						<el-button type="primary" plain size="small" :loading="pageConf.submit_btn_isload"
							@click="transSubmit()" v-if="showTransBtn()">确认转交
						</el-button>
						<el-button type="default" plain size="small" :loading="pageConf.submit_btn_isload"
							@click="pageConf.model = 1">返回详情
						</el-button>
					</div>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getCurrentInstance,
		defineComponent,
		ref,
		reactive,
		onMounted,
		watch
	} from 'vue'
	export default defineComponent({
		name: 'task_manage_process',
		props: ['is_manage_page'],
		setup(props, context) {
			// 访问 globalProperties
			const ctx = getCurrentInstance().appContext.config.globalProperties

			// 弹出层配置文件
			const pageConf = reactive({
				show: false,
				model: 1,
				submit_btn_isload: false
			})

			// 表单-挂载
			const formRef = ref()

			// 子任务列表
			const taskInfo = reactive({
				task_id: '',
				group: [],
				list: []
			})

			// 显示页面
			const openPage = (task_id) => {
				// 显示页面
				pageConf.show = true

				// 切换模式为任务进度详情
				pageConf.model = 1

				// 存放主任务ID
				taskInfo.task_id = task_id

				// 获取子任务列表
				getTaskList()
			}

			// 获取子任务列表
			const getTaskList = () => {
				// 执行请求操作
				ctx.$request_.post(ctx.$api_.state.Task.MyTask.process.url, {
						task_id: taskInfo.task_id,
						is_manage: typeof props.is_manage_page === 'undefined' ? false : props
							.is_manage_page
					})
					.then((respon) => {
						if (respon.Code === 200) {
							// 加载子任务信息
							taskInfo.list = respon.Data
						} else {
							ctx.$notify({
								title: '温馨提示',
								message: respon.Message,
								type: 'warning'
							})
						}
					})
			}

			// 是否显示任务批量开始按钮
			const showStartBtn = () => {
				let isShow = false
				if (taskInfo.list.length > 0) {
					taskInfo.list.forEach((v, i) => {
						if (v.status === 1) {
							isShow = true
						}
					})
				}

				return isShow
			}

			// 是否显示任务批量完成按钮
			const showSucBtn = () => {
				let isShow = false
				if (taskInfo.list.length > 0) {
					taskInfo.list.forEach((v, i) => {
						if (v.status === 2) {
							isShow = true
						}
					})
				}

				return isShow
			}

			// 是否显示任务转交按钮
			const showTransBtn = () => {
				let isShow = false
				if (taskInfo.list.length > 0) {
					taskInfo.list.forEach((v, i) => {
						if (v.status === 1 || v.status === 2 || v.status === 6) {
							isShow = true
						}
					})
				}

				return isShow
			}

			// 开始任务
			const startTaskList = (isBacth, index) => {
				let confirmTitle = '您确认要开始当前子任务吗'
				let task_list_ids = []

				if (isBacth === true) {
					confirmTitle = '你确认要批量开始所有子任务吗'

					// 批量循环待开始的任务信息
					taskInfo.list.forEach((v, i) => {
						if (v.status === 1) {
							task_list_ids.push(v.task_list_id)
						}
					})
				} else {
					task_list_ids.push(taskInfo.list[index].task_list_id)
				}

				ctx.$confirm(confirmTitle, '温馨提示', {
						confirmButtonText: '确认',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						ctx.$request_.post(ctx.$api_.state.Task.MyTask.start.url, {
								task_id: taskInfo.task_id,
								task_list_ids: task_list_ids
							})
							.then((respon) => {
								if (respon.Code === 200) {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'success'
									})

									// 重新加载子任务信息
									getTaskList()

									// 抛出
									context.emit('load_table')
								} else {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'warning'
									})
								}
							})
					}).catch(() => {})
			}

			// 暂停任务
			const stopTaskList = (isBacth, index) => {
				let confirmTitle = '您确认要暂停当前子任务吗'
				let task_list_ids = []

				if (isBacth === true) {
					/* 暂未开发 */ 
					confirmTitle = '你确认要批量暂停所有子任务吗'

					// 批量循环待暂停的任务信息
					taskInfo.list.forEach((v, i) => {
						if (v.status === 1) {
							task_list_ids.push(v.task_list_id)
						}
					})
				} else {
					task_list_ids.push(taskInfo.list[index].task_list_id)
				}

				ctx.$confirm(confirmTitle, '温馨提示', {
						confirmButtonText: '确认',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						ctx.$request_.post(ctx.$api_.state.Task.MyTask.stop.url, {
								task_id: taskInfo.task_id,
								task_list_ids: task_list_ids
							})
							.then((respon) => {
								if (respon.Code === 200) {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'success'
									})

									// 重新加载子任务信息
									getTaskList()

									// 抛出
									context.emit('load_table')
								} else {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'warning'
									})
								}
							})
					}).catch(() => {})
			}

			// 完成任务
			const sucTaskList = (isBacth, index) => {
				let confirmTitle = '您确认要完成当前子任务吗'
				let task_list_ids = []

				if (isBacth === true) {
					confirmTitle = '你确认要批量完成所有子任务吗'

					// 批量循环待完成的任务信息
					taskInfo.list.forEach((v, i) => {
						if (v.status === 2) {
							task_list_ids.push(v.task_list_id)
						}
					})
				} else {
					task_list_ids.push(taskInfo.list[index].task_list_id)
				}

				ctx.$prompt('请输入完成备注', '温馨提示', {
						confirmButtonText: '确认',
						cancelButtonText: '取消'
					})
					.then(({
						value
					}) => {
						ctx.$request_.post(ctx.$api_.state.Task.MyTask.success.url, {
								task_id: taskInfo.task_id,
								task_list_ids: task_list_ids,
								remark: typeof value === 'undefined' ? '' : value
							})
							.then((respon) => {
								if (respon.Code === 200) {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'success'
									})

									// 重新加载子任务信息
									getTaskList()

									// 抛出
									context.emit('load_table')
								} else {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'warning'
									})
								}
							})
					})
					.catch(() => {})
			}

			// 切换为任务转交页面
			const cutToTransPage = () => {
				// 切换模式
				pageConf.model = 2

				// 重置表单数据
				trans.datas.task_list_id = []
				trans.datas.task_list = []
				trans.datas.group_uid = ''

				if (taskInfo.group.length <= 0) {
					// 获取团队成员信息
					getTaskGroup()
				}
			}

			// 获取团队成员信息
			const getTaskGroup = () => {
				ctx.$request_.post(ctx.$api_.state.Task.BaseInfo.group_info.url, {
						isSearchMyself: false
					})
					.then((respon) => {
						if (respon.Code === 200) {
							// 加载团队成员信息
							taskInfo.group = respon.Data
						} else {
							ctx.$notify({
								title: '温馨提示',
								message: respon.Message,
								type: 'warning'
							})
						}
					})
			}

			// 任务转交-数据表单
			const trans = reactive({
				datas: {
					task_list_id: [],
					task_list: [],
					group_uid: ''
				},
				rules: {
					task_list_id: {
						required: true,
						message: '请选择子任务',
						trigger: 'blur'
					},
					group_uid: {
						required: true,
						message: '请选择转交人',
						trigger: 'blur'
					}
				}
			})

			// 任务转交-选择子任务
			const choseTransTask = (index) => {
				let transIndex = trans.datas.task_list_id.indexOf(taskInfo.list[index].task_list_id);
				if (transIndex > -1) {
					trans.datas.task_list_id.splice(transIndex, 1)
					trans.datas.task_list.splice(transIndex, 1)
				} else {
					trans.datas.task_list_id.push(taskInfo.list[index].task_list_id)
					trans.datas.task_list.push({
						task_list_id: taskInfo.list[index].task_list_id,
						title: taskInfo.list[index].title,
						remark: ''
					})
				}
			}

			// 任务转交-提交数据
			const transSubmit = () => {
				formRef.value.validate((valid) => {
					if (valid === true) {
						ctx.$request_.post(ctx.$api_.state.Task.MyTask.trans.url, {
								task_id: taskInfo.task_id,
								task_list: trans.datas.task_list,
								group_uid: trans.datas.group_uid
							})
							.then((respon) => {
								if (respon.Code === 200) {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'success'
									})

									// 判断转交数是否等于当前任务数，是则关闭进度页面打开详情页面
									if (trans.datas.task_list.length < taskInfo.list.length) {
										// 切换至任务进度详情模式
										pageConf.model = 1

										// 重新加载子任务信息
										getTaskList()


									} else {
										pageConf.show = false

										// 抛出
										context.emit('load_table_detail', 0, taskInfo.task_id)
									}

									// 抛出
									context.emit('load_table')
								} else {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'warning'
									})
								}
							})
					}
				})
			}

			// 转交确认
			const transferConfirm = (index) => {
				ctx.$confirm('请确认是否将此任务转交与您吗?', '温馨提示', {
						confirmButtonText: '确认',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						ctx.$request_.post(ctx.$api_.state.Task.MyTask.transfer_confirm.url, {
								task_id: taskInfo.task_id,
								task_list_id: taskInfo.list[index].task_list_id
							})
							.then((respon) => {
								if (respon.Code === 200) {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'success'
									})

									// 重新加载子任务信息
									getTaskList()

									// 抛出
									context.emit('load_table')
								} else {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'warning'
									})
								}
							})
					})
					.catch(() => {})
			}

			return {
				pageConf,
				formRef,
				taskInfo,
				openPage,
				showStartBtn,
				showSucBtn,
				showTransBtn,
				startTaskList,
				stopTaskList,
				sucTaskList,
				cutToTransPage,
				trans,
				choseTransTask,
				transSubmit,
				transferConfirm
			}
		}
	})
</script>

<style lang="less">
	#task_manage_process {
		.el-dialog__header {
			margin-bottom: 0;
			padding-bottom: 1vw;
			border-bottom: 1px solid #e6e6e6;

			.title_close {
				flex: none;
				color: #999999;
				font-size: 0.8vw;
				cursor: pointer;
				width: 5vw;

				i {
					font-size: 0.7vw;
				}
			}
		}

		.process-ul {
			list-style: none;
			padding: 0;

			.process-row {
				background-color: #f7f8fa;
				margin: 0.7vw 0 0 0 !important;
				padding: 0.5vw 0;
				line-height: 1vw;
				display: flex;
				align-items: center;
				color: #333333;

				.list-content-right {
					text-align: right;
					font-size: 0.67vw;

					.user {
						margin-right: 0.2vw;
					}
				}
			}
		}

		.trans-form {
			.trans-sub-title {
				font-size: 0.7vw;
				color: #cccccc;
			}

			.trans-ul {
				list-style: none;
				padding: 0;

				.trans-row {
					background-color: #f7f8fa;
					margin: 0.3vw 0 0 0 !important;
					padding: 0.5vw 0;
					line-height: 1vw;
					display: flex;
					justify-content: center;
					align-items: center;
					color: #333333;
					cursor: pointer;
				}

				.trans-row:hover {
					color: #0056fc;
				}

				.trans-row-active {
					color: #0056fc;
				}
			}

			.trans-remark-title {
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
			}

			.el-radio-button {
				margin: 0.5vw 0.5vw 0 0;

				.el-radio-button__inner {
					border: 1px solid #dcdfe6 !important;
					border-radius: 0.2vw !important;
					-webkit-box-shadow: none !important;
					box-shadow: none !important;
				}
			}
		}
	}
</style>
